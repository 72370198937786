import { default as ai_45form_45builderhMR23cuXk9Meta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/ai-form-builder.vue?macro=true";
import { default as edit5680hKp7ZcMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexxIEa8XnKxwMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/[slug]/index.vue?macro=true";
import { default as indexrkrMU2BSjCMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexsH4BHChjuXMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as share1ytLpg2YMrMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as statsGoJEq6PWUnMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionsYUgw06qsS1Meta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as showUGVfM8bXdxMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/[slug]/show.vue?macro=true";
import { default as guestuK4ElSB582Meta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/create/guest.vue?macro=true";
import { default as indexRLpK5ErLv3Meta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/create/index.vue?macro=true";
import { default as home5IEa0IMDsnMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/home.vue?macro=true";
import { default as indexMoGG1nhAGKMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/index.vue?macro=true";
import { default as loginjQKcWakenwMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/login.vue?macro=true";
import { default as callbackP1iBLzXdYfMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/oauth/callback.vue?macro=true";
import { default as emailZwdAtiPPYBMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/password/email.vue?macro=true";
import { default as _91token_93PNCRoGr5utMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/password/reset/[token].vue?macro=true";
import { default as pricingck1XX5XUhVMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyLcBLOEc7lgMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/privacy-policy.vue?macro=true";
import { default as register0XriLv3sT7Meta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/register.vue?macro=true";
import { default as access_45tokenslffty0SvGxMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountNj8SegfasFMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings/account.vue?macro=true";
import { default as adminIr2vfeDJTJMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings/admin.vue?macro=true";
import { default as billingm3BQLsfroHMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings/billing.vue?macro=true";
import { default as connectionszVj7GK5QwpMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings/connections.vue?macro=true";
import { default as indexH9ASdo4kLlMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings/index.vue?macro=true";
import { default as passworda5IPFk0b7jMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings/password.vue?macro=true";
import { default as profiledJ7FWp2cvDMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings/profile.vue?macro=true";
import { default as workspacei5CdresaeXMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings/workspace.vue?macro=true";
import { default as settingsXbB2GTG8U3Meta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings.vue?macro=true";
import { default as errorWN2Ln0fhsHMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/subscriptions/error.vue?macro=true";
import { default as success06aGZlXt5dMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93EjdJExMpCvMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/templates/[slug].vue?macro=true";
import { default as index7ojHnlSYcbMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/templates/index.vue?macro=true";
import { default as _91slug_93ABv45jDzrgMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatessnAtZCFzyaMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93RafGG81jqaMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionsPokwrFn3mkMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/terms-conditions.vue?macro=true";
import { default as update_45credentials7eJDJcByKnMeta } from "/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/update-credentials.vue?macro=true";
export default [
  {
    name: "ai-form-builder",
    path: "/ai-form-builder",
    meta: ai_45form_45builderhMR23cuXk9Meta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/ai-form-builder.vue")
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: edit5680hKp7ZcMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/[slug]/edit.vue")
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/[slug]/index.vue")
  },
  {
    name: showUGVfM8bXdxMeta?.name,
    path: "/forms/:slug()/show",
    meta: showUGVfM8bXdxMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/[slug]/show.vue"),
    children: [
  {
    name: "forms-slug-show",
    path: "",
    meta: indexrkrMU2BSjCMeta || {},
    redirect: indexrkrMU2BSjCMeta?.redirect,
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/[slug]/show/index.vue")
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indexsH4BHChjuXMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/[slug]/show/integrations/index.vue")
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: share1ytLpg2YMrMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/[slug]/show/share.vue")
  },
  {
    name: "forms-slug-show-stats",
    path: "stats",
    meta: statsGoJEq6PWUnMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/[slug]/show/stats.vue")
  },
  {
    name: "forms-slug-show-submissions",
    path: "submissions",
    meta: submissionsYUgw06qsS1Meta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/[slug]/show/submissions.vue")
  }
]
  },
  {
    name: "forms-create-guest",
    path: "/forms/create/guest",
    meta: guestuK4ElSB582Meta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/create/guest.vue")
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: indexRLpK5ErLv3Meta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/forms/create/index.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: home5IEa0IMDsnMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/home.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginjQKcWakenwMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/login.vue")
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    meta: callbackP1iBLzXdYfMeta || {},
    alias: ["/oauth/:provider/callback"],
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/oauth/callback.vue")
  },
  {
    name: "password-email",
    path: "/password/email",
    meta: emailZwdAtiPPYBMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/password/email.vue")
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93PNCRoGr5utMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/password/reset/[token].vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingck1XX5XUhVMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/pricing.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyLcBLOEc7lgMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: register0XriLv3sT7Meta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/register.vue")
  },
  {
    name: settingsXbB2GTG8U3Meta?.name,
    path: "/settings",
    meta: settingsXbB2GTG8U3Meta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings.vue"),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings/access-tokens.vue")
  },
  {
    name: "settings-account",
    path: "account",
    meta: accountNj8SegfasFMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings/account.vue")
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: adminIr2vfeDJTJMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings/admin.vue")
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingm3BQLsfroHMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings/billing.vue")
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connectionszVj7GK5QwpMeta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings/connections.vue")
  },
  {
    name: "settings",
    path: "",
    meta: indexH9ASdo4kLlMeta || {},
    redirect: indexH9ASdo4kLlMeta?.redirect,
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings/index.vue")
  },
  {
    name: "settings-password",
    path: "password",
    meta: passworda5IPFk0b7jMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings/password.vue")
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profiledJ7FWp2cvDMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings/profile.vue")
  },
  {
    name: "settings-workspace",
    path: "workspace",
    meta: workspacei5CdresaeXMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/settings/workspace.vue")
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: errorWN2Ln0fhsHMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/subscriptions/error.vue")
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: success06aGZlXt5dMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/subscriptions/success.vue")
  },
  {
    name: "templates-slug",
    path: "/templates/:slug()",
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/templates/[slug].vue")
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/templates/index.vue")
  },
  {
    name: "templates-industries-slug",
    path: "/templates/industries/:slug()",
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/templates/industries/[slug].vue")
  },
  {
    name: "templates-my-templates",
    path: "/templates/my-templates",
    meta: my_45templatessnAtZCFzyaMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/templates/my-templates.vue")
  },
  {
    name: "templates-types-slug",
    path: "/templates/types/:slug()",
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/templates/types/[slug].vue")
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionsPokwrFn3mkMeta || {},
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/terms-conditions.vue")
  },
  {
    name: "update-credentials",
    path: "/update-credentials",
    component: () => import("/home/forge/forms.farbcode.net/releases/20240916133810/client/pages/update-credentials.vue")
  }
]