import { defineNuxtPlugin } from '#app/nuxt'
import { LazyDiscordIntegration, LazyEmailIntegration, LazyGoogleSheetsIntegration, LazySlackIntegration, LazySubmissionConfirmationIntegration, LazyWebhookIntegration, LazyZapierIntegration, LazyGoogleSheetsIntegrationActions, LazyIntegrationCard, LazyIntegrationEventsModal, LazyIntegrationListOption, LazyIntegrationModal, LazyIntegrationWrapper, LazyNotificationsMessageActions, LazyCheckboxInput, LazyCodeInput, LazyColorInput, LazyDateInput, LazyFileInput, LazyFlatSelectInput, LazyImageInput, LazyMatrixInput, LazyPhoneInput, LazyRatingInput, LazyRichTextAreaInput, LazyScaleInput, LazySelectInput, LazySignatureInput, LazySliderInput, LazyTextAreaInput, LazyTextInput, LazyToggleSwitchInput, LazyVForm, LazyCameraUpload, LazyCheckboxIcon, LazyInputHelp, LazyInputLabel, LazyInputWrapper, LazyRadioButtonIcon, LazyUploadedFile, LazyVCheckbox, LazyVSelect, LazyVSwitch, LazyUseFormInput, LazyAlert, LazyAlertError, LazyAlertSuccess, LazyHasError, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["DiscordIntegration", LazyDiscordIntegration],
["EmailIntegration", LazyEmailIntegration],
["GoogleSheetsIntegration", LazyGoogleSheetsIntegration],
["SlackIntegration", LazySlackIntegration],
["SubmissionConfirmationIntegration", LazySubmissionConfirmationIntegration],
["WebhookIntegration", LazyWebhookIntegration],
["ZapierIntegration", LazyZapierIntegration],
["GoogleSheetsIntegrationActions", LazyGoogleSheetsIntegrationActions],
["IntegrationCard", LazyIntegrationCard],
["IntegrationEventsModal", LazyIntegrationEventsModal],
["IntegrationListOption", LazyIntegrationListOption],
["IntegrationModal", LazyIntegrationModal],
["IntegrationWrapper", LazyIntegrationWrapper],
["NotificationsMessageActions", LazyNotificationsMessageActions],
["CheckboxInput", LazyCheckboxInput],
["CodeInput", LazyCodeInput],
["ColorInput", LazyColorInput],
["DateInput", LazyDateInput],
["FileInput", LazyFileInput],
["FlatSelectInput", LazyFlatSelectInput],
["ImageInput", LazyImageInput],
["MatrixInput", LazyMatrixInput],
["PhoneInput", LazyPhoneInput],
["RatingInput", LazyRatingInput],
["RichTextAreaInput", LazyRichTextAreaInput],
["ScaleInput", LazyScaleInput],
["SelectInput", LazySelectInput],
["SignatureInput", LazySignatureInput],
["SliderInput", LazySliderInput],
["TextAreaInput", LazyTextAreaInput],
["TextInput", LazyTextInput],
["ToggleSwitchInput", LazyToggleSwitchInput],
["VForm", LazyVForm],
["CameraUpload", LazyCameraUpload],
["CheckboxIcon", LazyCheckboxIcon],
["InputHelp", LazyInputHelp],
["InputLabel", LazyInputLabel],
["InputWrapper", LazyInputWrapper],
["RadioButtonIcon", LazyRadioButtonIcon],
["UploadedFile", LazyUploadedFile],
["VCheckbox", LazyVCheckbox],
["VSelect", LazyVSelect],
["VSwitch", LazyVSwitch],
["UseFormInput", LazyUseFormInput],
["Alert", LazyAlert],
["AlertError", LazyAlertError],
["AlertSuccess", LazyAlertSuccess],
["HasError", LazyHasError],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
