import revive_payload_client_4sVQNw7RlN from "/home/forge/forms.farbcode.net/releases/20240916133810/client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/forge/forms.farbcode.net/releases/20240916133810/client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/forge/forms.farbcode.net/releases/20240916133810/client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/forge/forms.farbcode.net/releases/20240916133810/client/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/forge/forms.farbcode.net/releases/20240916133810/client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/forge/forms.farbcode.net/releases/20240916133810/client/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/forge/forms.farbcode.net/releases/20240916133810/client/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/forge/forms.farbcode.net/releases/20240916133810/client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/forge/forms.farbcode.net/releases/20240916133810/client/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/forge/forms.farbcode.net/releases/20240916133810/client/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/forge/forms.farbcode.net/releases/20240916133810/client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_X7whXyrIWR from "/home/forge/forms.farbcode.net/releases/20240916133810/client/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/home/forge/forms.farbcode.net/releases/20240916133810/client/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/home/forge/forms.farbcode.net/releases/20240916133810/client/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/home/forge/forms.farbcode.net/releases/20240916133810/client/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/home/forge/forms.farbcode.net/releases/20240916133810/client/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import motion_jmBwdqbgTJ from "/home/forge/forms.farbcode.net/releases/20240916133810/client/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import amplitude_EZuIopuxgB from "/home/forge/forms.farbcode.net/releases/20240916133810/client/plugins/amplitude.js";
import crisp_client_amQNA6tQA4 from "/home/forge/forms.farbcode.net/releases/20240916133810/client/plugins/crisp.client.js";
import featureFlags_yTfIldzVS7 from "/home/forge/forms.farbcode.net/releases/20240916133810/client/plugins/featureFlags.js";
import pinia_history_Fm3e3342Dv from "/home/forge/forms.farbcode.net/releases/20240916133810/client/plugins/pinia-history.js";
import sentry_PZVGm6LH3G from "/home/forge/forms.farbcode.net/releases/20240916133810/client/plugins/sentry.js";
import vue_confetti_client_S5fochbKaT from "/home/forge/forms.farbcode.net/releases/20240916133810/client/plugins/vue-confetti.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  motion_jmBwdqbgTJ,
  amplitude_EZuIopuxgB,
  crisp_client_amQNA6tQA4,
  featureFlags_yTfIldzVS7,
  pinia_history_Fm3e3342Dv,
  sentry_PZVGm6LH3G,
  vue_confetti_client_S5fochbKaT
]